export const whatDecimalSeparator = (locale) => {
  const numberWithDecimalSeparator = 1.1;
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithDecimalSeparator)
    .find((part) => part.type === "decimal").value;
};

export const calcDate = (d) => {
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(d.getDate()).padStart(2, "0")}`;
};

export const calcTime = (d) => {
  return `${String(d.getHours()).padStart(2, "0")}:${String(
    d.getMinutes()
  ).padStart(2, "0")}:${String(d.getSeconds()).padStart(2, "0")}`;
};

export const optimizeValueDisplay = (value) => {
  if (value && value.includes("\n")) {
    const valueArray = value.split("\n");
    let multilineValue = [];
    for (let ii = 0; ii < valueArray.length; ii++) {
      valueArray[ii] !== "" &&
        multilineValue.push(<div key={`v-${ii}`}>{valueArray[ii]}</div>);
    }
    return multilineValue;
  }
  return value;
};

export const getMyIP = () => {
  return new Promise((resolve, reject) => {
    fetch("https://api.ipify.org/?format=json")
      .then((res) => {
        checkForErrorFetch(res)
          .then((response) => {
            if (response.ip) {
              resolve(response);
            } else {
              resolve({ ip: "No IP found" });
            }
          })
          .catch((err) => {
            console.error(err);
            resolve({ ip: "Fetch IP error" });
          });
      })
      .catch((err) => {
        console.error("Error 012");
        resolve({ ip: "Fetch IP error" });
      });
  });
};

export const checkForErrorFetch = (response) => {
  if (!response.ok) throw Error("Error 018");
  return response.json();
};

export const getPropsForDeviceChart = (index, devId, machineType) => {
  let typeOfChart = "other";
  let wsCommand = "";

  // Ekran sprężarki
  if (devId.search("sp") >= 0) {
    wsCommand = `WP${index};`;
    typeOfChart = "compressor:" + machineType;
  }

  // Ekran MSN
  if (devId === "msn") {
    wsCommand = "WM;";
    typeOfChart = "msn";
    if (machineType === "MSN-13") {
      typeOfChart = "msn-nitrogen";
    }
  }

  // Ekran DV
  if (devId.search("dv") >= 0) {
    wsCommand = `WD${index};`;
    typeOfChart = "device:" + machineType;
  }

  return {
    typeOfChart: typeOfChart,
    wsCommand: wsCommand,
  };
};

export const setTooltip = ({
  dateStart,
  timeStart,
  dateEnd,
  timeEnd,
  params,
  maxRange,
  dateFromHP,
  chart,
  dateStartInt,
  dateEndInt,
  t
}) => {
  if (chart) {
    let valueIsNaN = false;
    let units = [];
    for (let x in params) {
      if (isNaN(params[x].value)) {
        valueIsNaN = true;
        break;
      }
      if (!units.includes(params[x].unit)) {
        units.push(params[x].unit);
      }
    }

    if (Object.keys(params).length > 20) {
      return "Wybierz maksymalnie 20 parametrów!";
    } else if (valueIsNaN) {
      return "Wybrano parametr niedostępny!";
    } else if (units.length > 2) {
      return "Wybierz maksymalnie 2 rodzaje jednostek!";
    } else {
      return "";
    }
  } else {
    const lastAvailableDate = dateFromHP
      ? Number(new Date(dateFromHP))
      : Number(new Date());
    const now = Number(new Date());
    const firstDate = Number(new Date("2020-12-01T00:00:00"));
    const dateAndTimeStart = dateStartInt
      ? dateStartInt
      : Number(new Date(`${dateStart}T${timeStart}`));
    const dateAndTimeEnd = dateEndInt
      ? dateEndInt
      : Number(new Date(`${dateEnd}T${timeEnd}`));
    if (
      dateStart === "" ||
      dateEnd === "" ||
      timeStart === "" ||
      timeEnd === "" ||
      (params !== undefined && Object.keys(params).length === 0)
    ) {
      return "Uzupełnij dane";
    } else if (dateAndTimeEnd < dateAndTimeStart) {
      return "Wybierz właściwą datę!";
    } else if (dateAndTimeEnd < firstDate || dateAndTimeStart < firstDate) {
      return "Brak możliwości wyboru daty przed 2020-12-01 00:00:00";
    } else if (dateAndTimeEnd > lastAvailableDate) {
      if (dateAndTimeEnd > now) {
        return "Nie możesz wybrać przyszłej daty!";
      } else {
        return `${t("Ostatnia dostępna data to")} ${dateFromHP}`;
      }
    } else if (
      dateAndTimeEnd < dateAndTimeStart + 600000 &&
      dateAndTimeEnd >= dateAndTimeStart
    ) {
      return "Minimalny zakres to 10 minut!";
    } else if (maxRange && dateAndTimeEnd - dateAndTimeStart > maxRange[1]) {
      return "Przekroczono maksymalny zakres!";
    } else {
      return "";
    }
  }
};

/**
 * Przeliczenie statusu obiektu na podstawie parametrów zapytania HP
 */
export const setBuildingStatus = (devicesData) => {
  let status = "OK";

  for (let i in devicesData) {
    if (i.includes("SP")) {
      if (
        devicesData[i]["Stan pracy"] &&
        devicesData[i]["Stan pracy"]["value"] === "Sprężarka wyłączona awaria"
      ) {
        status = "Awaria";
      }
    }
    if (i.includes("DV")) {
      for (let x in devicesData[i]) {
        if (
          devicesData[i][x].group &&
          devicesData[i][x].group.includes("Alarmy") &&
          devicesData[i][x].value.search(/Awaria|Alarm/) >= 0
        ) {
          status = "Awaria";
        }
      }
    }
  }
  
  return status;
};

export const decToBin = (valueAsString, length = undefined) => {
  // Konwersja stringa na liczbę
  const value = parseInt(valueAsString, 10);
  if (isNaN(value)) {
    return 'Nieprawidłowa liczba'; // Obsługa błędu, gdy string nie jest liczbą
  }

  // Konwersja liczby na binarną
  let binaryString = value.toString(2);

  // Dodanie zer na początku, jeśli długość jest zdefiniowana i większa niż długość binaryString
  if (length && length > binaryString.length) {
    binaryString = '0'.repeat(length - binaryString.length) + binaryString;
  }

  return binaryString;
};